import { Drawer, makeStyles } from '@material-ui/core'
import * as React from 'react'
// import CloseIcon from '@material-ui/icons/Close'
// import ChevronRightIcon from '@material-ui/icons/ChevronRight'
// import { useGetActivityQuery, useMarkAllAsReadMutation } from 'store/modules/activity/activity.query'
// import { getToken } from 'utils/token'
// import { Link as RouterLink } from 'react-router-dom'
// import { useSnackbar } from 'notistack'

type Anchor = 'right'

const useStyles = makeStyles(theme => ({
    notificationsDrawer: {
        top: '93px !important',
        '& .MuiDrawer-paperAnchorRight': {
            top: '93px',
            minWidth: 480,
            [theme.breakpoints.down('sm')]: {
                mindWidth: 'auto',
                top: 0,
                left: 0,
                right: 0,
            },
        },
        '& .MuiBackdrop-root': {
            top: '93px',
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    subheader: {
        padding: 16,
        paddingTop: 0,
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },
    notificationsContainer: {
        width: 480,
        overflowY: 'scroll',
        height: 'calc(100% - 310px)',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            width: 'auto',
        },
    },
    iconContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 32,
            justifyContent: 'space-between',
        },
    },
}))

interface IGeneralDrawer {
    isOpen: boolean
    onChangeOpenDrawer: (open) => void
    title?: string
    children: any
}

export default React.memo(function GeneralDrawer(props: IGeneralDrawer) {
    const classes = useStyles()
    // const access_token = getToken('access_token')
    // const { enqueueSnackbar } = useSnackbar()

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return
        }
        props.onChangeOpenDrawer(open)
    }

    return (
        <div style={{ zIndex: 1000, right: 0, top: 200, position: 'absolute' }}>
            <React.Fragment key={'right'}>
                <Drawer className={classes.notificationsDrawer} anchor={'right'} open={props.isOpen} onClose={toggleDrawer('right', false)}>
                    <div className={classes.header}>
                        {/* <Typography variant="h2" style={{ fontSize: 24 }}>
                            {props.drawerTitle}
                        </Typography> */}
                        {/* <Box display="flex" gridColumnGap={24} className={classes.iconContainer}>
                            <Box display="flex" gridColumnGap={18}>
                                <CloseIcon onClick={toggleDrawer('right', false)} />
                            </Box>
                        </Box> */}
                    </div>

                    <div style={{ maxWidth: 480, marginBottom: '10rem' }}>{props.children}</div>
                </Drawer>
            </React.Fragment>
        </div>
    )
})

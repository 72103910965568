import { useCallback, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'

import { Avatar, Button, CircularProgress, makeStyles, TextareaAutosize, Tooltip, Typography } from '@material-ui/core'
import { colorPalette } from 'styles/mainTheme'

import './CommentRoom.css'

import { useDeleteCommentMutation, usePartialUpdateCommentMutation } from 'store/modules/comment/comment.query'
import { CommentContentType } from 'store/modules/comment/enum'
import { ReduxCommentTypeNamespace } from 'store/modules/comment/comment'
import { getDifferentTime, toSecondUnixFormat } from 'utils/time'

import Send from '@material-ui/icons/Send'
import { Check, CloseOutlined, LinkTwoTone } from '@material-ui/icons'
import { strToSentences } from 'utils/general'
import { ThreadHeader } from './ThreadHeader'
import { useHistory } from 'react-router-dom'
import { useGetCommentator } from './hooks/useGetCommentator'

const useStyles = makeStyles(theme => ({
    messageTextArea: {
        width: '100%',
        padding: '8px',
        fontSize: '12px',
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px solid rgba(0, 0, 0, 0.26)',
        borderRadius: '3px',
        fontFamily: 'Inter',
    },
}))

type ThreadProps = {
    /**
     * Required for comment (not used for reply)
     */
    mainCommentMeta: {
        elementId: string
        contentType: CommentContentType
        contentTypeId: string
    }
    canResolve?: boolean
    canDelete?: boolean
    canEdit?: boolean
    data?: ReduxCommentTypeNamespace.ICommentItem
    isCommentDialog?: boolean
    /**
     * the default is false
     */
    hasHeaderAction?: boolean
    headerMeta?: {
        isEditMode?: boolean
        isDeleteMode?: boolean
        setIsEditMode?: Function
        setIsDeleteMode?: Function
        onClose?: Function
    }
}

type ReplyCommentItemProps = {
    parentId: string
    mainCommentMeta: {
        elementId: string
        contentType: CommentContentType
        contentTypeId: string
    }
    isCommentDialog?: boolean
    data?: ReduxCommentTypeNamespace.ICommentItem
    isInputForm?: boolean
    canDelete?: boolean
    canEdit?: boolean
}

const CommentAvatar = ({ authorName, isThread }: { authorName: string; isThread: boolean }) => (
    <Tooltip title={authorName}>
        <Avatar
            style={{
                backgroundColor: isThread ? colorPalette.mediumBlue : colorPalette.bluePrimary,
                width: '32px',
                height: '32px',
            }}
            className="comment-card__header__profile-picture"
            children={authorName
                .split(' ')
                .map(n => n[0])
                .join('')}
        />
    </Tooltip>
)

const CommentItem = (props: ThreadProps & ReplyCommentItemProps) => {
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()

    const { mainCommentMeta, parentId, data, isInputForm, hasHeaderAction, headerMeta, isCommentDialog, canResolve, canDelete, canEdit } =
        props
    const isThread = !parentId

    const selectedUnixTime = toSecondUnixFormat(data?.modified_on_ts || data?.created_on_ts || new Date().getTime() / 1000)
    const isAlreadyEdited = data?.created_on_ts && data?.modified_on_ts && data.created_on_ts !== data.modified_on_ts

    const [isEditMode, setIsEditMode] = useState(false)
    const [isDeleteMode, setIsDeleteMode] = useState(false)

    const [timeAgo, setTimeAgo] = useState(getDifferentTime(selectedUnixTime))
    const [message, setMessage] = useState(data?.text || '')
    const [errorMessage, setErrorMessage] = useState('')
    const classes = useStyles()

    const isPreviewMode = !isEditMode && data

    const { currentUserId, authorName, companyName } = useGetCommentator({ data })

    const [deleteComment, { isLoading: isDeletingComment }] = useDeleteCommentMutation()
    const [partialUpdateComment, { isLoading: isUpdatingComment }] = usePartialUpdateCommentMutation()

    const shouldDisableAction = isUpdatingComment || isDeletingComment

    const onDelete = () => {
        if (data) {
            close()
            deleteComment({
                id: data.id,
                content: {
                    content_type: mainCommentMeta.contentType,
                    object_id: mainCommentMeta.contentTypeId,
                    parentId: parentId,
                },
            })
                .unwrap()
                .then(() => {
                    enqueueSnackbar('Successfully deleted your comment!', { variant: 'success' })
                })
                .catch(() => {
                    enqueueSnackbar('Failed deleted your comment!', { variant: 'info' })
                })
        }
    }

    const onSave = async e => {
        e.preventDefault()
        e.stopPropagation()

        setErrorMessage('')
        if (shouldDisableAction) {
            return
        }

        try {
            if (data) {
                await partialUpdateComment({
                    id: data.id,
                    body: {
                        text: message,
                    },
                    content: {
                        content_type: mainCommentMeta.contentType,
                        object_id: mainCommentMeta.contentTypeId,
                        parentId: parentId,
                    },
                }).unwrap()
            }

            if (!isThread) {
                if (!data) {
                    // For creation comment (not thread) process, we should clear the current textarea input after success create a new comment
                    setMessage('')
                }
            }

            setTimeAgo('Now')
            setIsEditMode(false)

            enqueueSnackbar('Successfully update the comment', { variant: 'success' })
        } catch (error) {
            setErrorMessage('Something went wrong')
        }
    }

    const onCancel = () => {
        setIsEditMode(false)

        setMessage(data?.text || '')
    }

    const copyCommentLink = () => {
        if (isCommentDialog) {
            const commentLocation = `${window.location.origin}${window.location.pathname}?comment=${data?.id}`
            navigator.clipboard.writeText(commentLocation)

            enqueueSnackbar('Link copied', { variant: 'success' })
        } else {
            if (data?.extra_data.pathname) {
                // Close the drawer
                close()
                history.push(`${data?.extra_data.pathname}?comment=${data?.id}`)
            }
        }
    }

    const onChangeMessage = e => {
        setMessage(e.target.value)
    }

    const onFocusMessageInput = () => {
        setErrorMessage('')
    }

    const getFormButton = () => {
        // Will be show When the data is exist and user click the edit button
        if (isEditMode) {
            return (
                <>
                    <Button className="comment-form-action" variant="outlined" onClick={onCancel} disabled={shouldDisableAction}>
                        Cancel
                    </Button>
                    <Button
                        className="comment-form-action"
                        variant="contained"
                        onClick={onSave}
                        disabled={shouldDisableAction}
                        endIcon={shouldDisableAction && <CircularProgress size={16} />}
                    >
                        Save
                    </Button>
                </>
            )
        }

        return
    }

    const toDeleteMode = useCallback((isTrue: boolean) => setIsDeleteMode(isTrue), [])
    const toEditMode = useCallback((isTrue: boolean) => setIsEditMode(isTrue), [])
    const close = useCallback(() => {
        props.headerMeta?.onClose?.()
    }, [props])

    const commentatorId = data?.commentator.content_item_id ?? ''

    const contentTypeToDisplayText = {
        applicationsubmission: 'Application Question',
        opportunity: 'Transaction Details',
    }

    const isCurrentUserAuthor = currentUserId === commentatorId

    const getContent = () => {
        if (isPreviewMode) {
            return (
                <div className="comment-content">
                    {isThread && (
                        <p className="comment-meta">
                            <span className="comment-meta__content">
                                {contentTypeToDisplayText[props.mainCommentMeta.contentType] || props.mainCommentMeta.contentType}
                            </span>
                            <span>|</span>
                            <span className="comment-meta__content">
                                {strToSentences(data?.sub_comment_object ?? props.mainCommentMeta?.elementId)}
                            </span>
                        </p>
                    )}
                    <p className="comment-message" dangerouslySetInnerHTML={{ __html: message.replaceAll(/\n/g, '<br/>') }}></p>
                    <div className="comment-footer">
                        {isPreviewMode && (
                            <Typography className="comment-date">
                                {timeAgo}
                                {isAlreadyEdited ? ' (Edited)' : ''}
                            </Typography>
                        )}
                        {
                            <div className="comment-action">
                                <Button onClick={copyCommentLink}>
                                    <LinkTwoTone fontSize="small" />
                                </Button>

                                {isCurrentUserAuthor && !!canEdit && <Button onClick={() => setIsEditMode(true)}>Edit</Button>}

                                {!isThread && isCurrentUserAuthor && !!canDelete && (
                                    <Button onClick={() => setIsDeleteMode(true)}>Delete</Button>
                                )}
                            </div>
                        }
                    </div>
                </div>
            )
        }

        const minRows = isInputForm ? 1 : 6
        const placeholder = isInputForm ? 'Reply' : 'Type your comment'

        return (
            <div style={{ width: '100%' }}>
                <div style={{ position: 'relative', width: '100%' }}>
                    <TextareaAutosize
                        className={classes.messageTextArea}
                        minRows={minRows}
                        value={message}
                        placeholder={placeholder}
                        onChange={onChangeMessage}
                        onFocus={onFocusMessageInput}
                        style={errorMessage ? { borderColor: '#F15656' } : {}}
                        disabled={shouldDisableAction}
                    />
                    {!isEditMode && (
                        <div
                            style={{
                                position: 'absolute',
                                right: '2px',
                                bottom: '7px',

                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {shouldDisableAction && <CircularProgress size={16} />}
                            <Button
                                disabled={shouldDisableAction || !message.trim()}
                                style={{ background: 'transparent' }}
                                onClick={onSave}
                            >
                                <Send color={message.trim() ? 'primary' : 'disabled'} fontSize="small" />
                            </Button>
                        </div>
                    )}
                </div>
                <Typography color="error" variant="body2">
                    {errorMessage}
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>{getFormButton()}</div>
            </div>
        )
    }

    /**
     * Realtime comment datetime calculation
     */
    useEffect(() => {
        if (isPreviewMode && selectedUnixTime) {
            const updateTimeAgo = setInterval(() => {
                setTimeAgo(getDifferentTime(selectedUnixTime))
            }, 10_000)

            return () => clearInterval(updateTimeAgo)
        }
    }, [selectedUnixTime, isPreviewMode])

    /**
     * There's also a threadHeader in CommentRoom.tsx,
     * There's action edit and delete, so we need to subscribe the value from the parent to show the "mode" properly
     */
    useEffect(() => {
        if (!!headerMeta?.isEditMode) {
            setIsEditMode(true)
        } else if (!!headerMeta?.isDeleteMode) {
            setIsDeleteMode(true)
        }
    }, [headerMeta])

    /**
     * When the mode value changed to false,
     * We should notify the parent to also change the value
     */
    useEffect(() => {
        if (!isEditMode) {
            headerMeta?.setIsEditMode?.(false)
        }

        if (!isDeleteMode) {
            headerMeta?.setIsDeleteMode?.(false)
        }
    }, [isEditMode, isDeleteMode, headerMeta])

    const isLeftDirection = isThread
    const commentClass = isLeftDirection ? '' : 'comment-card-reply'

    return (
        <div className={`comment-card chat-room--container ${commentClass}`} id={`comment-${data?.id}`}>
            <div className="comment-card__header">
                <div>
                    {isLeftDirection && <CommentAvatar authorName={authorName} isThread={isThread} />}
                    <div className="comment-card__header__profile">
                        <Typography variant="body2">{authorName}</Typography>
                        <Typography variant="caption">{companyName}</Typography>
                    </div>
                    {!isLeftDirection && <CommentAvatar authorName={authorName} isThread={isThread} />}
                </div>
                <div>
                    {hasHeaderAction && isThread && data && (
                        <ThreadHeader
                            isCurrentUserAuthor={isCurrentUserAuthor}
                            containerClass=""
                            threads={[]}
                            onClose={close}
                            viewMode={'THREAD_VIEW'}
                            threadId={data.id}
                            threadStatus={data.status}
                            threadMeta={{
                                contentType: data.contentType,
                                contentTypeId: data.contentTypeId,
                            }}
                            headerProps={{
                                showCloseAction: false,
                                showNextPrevAction: false,
                                showViewAllAction: false,
                                showMenuAction: canResolve || canDelete || canEdit,
                                showResolveAction: !!canResolve,
                                onDelete: canDelete ? toDeleteMode : undefined,
                                onEdit: canEdit ? toEditMode : undefined,
                            }}
                        />
                    )}
                </div>
            </div>
            {getContent()}
            {isDeleteMode && (
                <div className="comment-card__dialog-confirmation chat-room--container">
                    <span>Are you sure want to delete this comment?</span>
                    <div>
                        {isDeletingComment && <CircularProgress size={18} style={{ color: '#FFFFFF' }} />}
                        <Button size="small" variant="outlined" disabled={isDeletingComment} onClick={() => setIsDeleteMode(false)}>
                            <CloseOutlined fontSize="small" />
                        </Button>
                        <Button size="small" variant="outlined" disabled={isDeletingComment} onClick={onDelete}>
                            <Check fontSize="small" />
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export const Thread = (props: ThreadProps) => <CommentItem {...props} parentId="" />
export const Comment = (props: ReplyCommentItemProps) => {
    return <CommentItem {...props} />
}

import { combineReducers, Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import generalReducer from 'store/modules/general'
import { level2Api } from 'store/modules/level2'
import { managementApi } from 'store/modules/management'
import { scheduleApi } from 'store/modules/schedule'
import surveyReducer, { surveyApi } from 'store/modules/survey'
import userReducer, { userApi } from 'store/modules/user'
import { AuthUtil } from 'utils/auth'
import { getToken } from 'utils/localStorage/token'
import { activityApiSlice } from './activity/activity.query'
import { algoOpportunityAPI } from './algo-opportunity/algo-opportunity.query'
import { authorizationApiSlice } from './authorization/authorization.query'
import { campaignApiSlice } from './campaign/campaign.query'
import { connectApiSlice } from './connect/connect.query'
import { investorApiSlice } from './investor/investor.query'
import { masterApiSlice } from './master/master.query'
import { matchingApiSlice } from './matching/matching.query'
import { opportunityApi } from './opportunity/opportunity.query'
import { originatorApiSlice } from './originator/originator.query'
import { taxonomyApiSlice } from './taxonomy/taxonomy.query'
import { marketplaceApi } from './marketplace/marketplace.query'
import { commercialEntityApiSlice } from './commercialentity/commercialentity.query'
import { ndaApiSlice } from './nda/nda.query'
import commentReducer, { commentApi } from './comment'
import { contentApiSlice } from './content/content.query'
import { reportApi } from './report/report.query'

export const combinedReducer = combineReducers({
    ...generalReducer,
    [connectApiSlice.reducerPath]: connectApiSlice.reducer,
    [contentApiSlice.reducerPath]: contentApiSlice.reducer,
    [investorApiSlice.reducerPath]: investorApiSlice.reducer,
    [masterApiSlice.reducerPath]: masterApiSlice.reducer,
    [matchingApiSlice.reducerPath]: matchingApiSlice.reducer,
    [originatorApiSlice.reducerPath]: originatorApiSlice.reducer,
    [opportunityApi.reducerPath]: opportunityApi.reducer,
    [algoOpportunityAPI.reducerPath]: algoOpportunityAPI.reducer,
    [taxonomyApiSlice.reducerPath]: taxonomyApiSlice.reducer,
    [scheduleApi.reducerPath]: scheduleApi.reducer,
    [level2Api.reducerPath]: level2Api.reducer,
    [managementApi.reducerPath]: managementApi.reducer,
    [activityApiSlice.reducerPath]: activityApiSlice.reducer,
    [campaignApiSlice.reducerPath]: campaignApiSlice.reducer,
    [authorizationApiSlice.reducerPath]: authorizationApiSlice.reducer,
    [marketplaceApi.reducerPath]: marketplaceApi.reducer,
    [ndaApiSlice.reducerPath]: ndaApiSlice.reducer,
    [commercialEntityApiSlice.reducerPath]: commercialEntityApiSlice.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    ...userReducer,
    ...surveyReducer,
    ...commentReducer,
})

const externalReducerPath: string[] = [
    activityApiSlice.reducerPath,
    commentApi.reducerPath,
    scheduleApi.reducerPath,
    matchingApiSlice.reducerPath,
    algoOpportunityAPI.reducerPath,
    level2Api.reducerPath,
    campaignApiSlice.reducerPath,
    opportunityApi.reducerPath,
]

// Still needs to be tested and in-sync with back-end error codes
const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => async action => {
    // Unauthorized
    if (action?.payload?.status === 401 && !externalReducerPath.includes(action?.type.split('/')[0])) {
        const refreshToken = getToken('refresh_token')

        if (refreshToken) {
            const { error, data } = await api.dispatch<any>(connectApiSlice.endpoints.refreshToken.initiate(refreshToken))
            if (typeof error === 'undefined' || typeof data?.refresh_token === 'undefined') {
                console.log('Force logout because refresh token not exist', action?.payload)
                AuthUtil.logout(api.dispatch)
            } else {
                window?.location?.reload()
            }
        } else {
            console.log('Force logout because refresh token not found', action?.payload)
            AuthUtil.logout(api.dispatch)
        }
    } else if (action?.payload?.status === 500 || action?.payload?.originalStatus === 500) {
        const params = new URLSearchParams(window.location.search)
        console.log(params)
        // if (!params.has('email')) window?.location?.assign?.('/oops?status=500')
    }

    return next(action)
}

export const combinedMiddleware = [
    connectApiSlice.middleware,
    investorApiSlice.middleware,
    scheduleApi.middleware,
    level2Api.middleware,
    reportApi.middleware,
    managementApi.middleware,
    masterApiSlice.middleware,
    matchingApiSlice.middleware,
    originatorApiSlice.middleware,
    opportunityApi.middleware,
    userApi.middleware,
    taxonomyApiSlice.middleware,
    surveyApi.middleware,
    algoOpportunityAPI.middleware,
    taxonomyApiSlice.middleware,
    investorApiSlice.middleware,
    activityApiSlice.middleware,
    campaignApiSlice.middleware,
    authorizationApiSlice.middleware,
    marketplaceApi.middleware,
    commercialEntityApiSlice.middleware,
    commentApi.middleware,
    contentApiSlice.middleware,
    ndaApiSlice.middleware,
    rtkQueryErrorLogger,
]

import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxUser } from 'store/modules/user/user'

const reducerPath = 'userApi'

export const userApi = createApi({
    reducerPath,
    baseQuery: http(),
    endpoints: builder => ({
        getUserProfile: builder.query<IReduxUser.IGetUserProfileResponse, Object | void>({
            query: () => ({
                url: '/v1/myprofile',
                method: 'GET',
            }),
            transformResponse: (response: IReduxUser.IGetUserProfileResponse): IReduxUser.IGetUserProfileResponse => {
                const roleNames = response?.user?.roleNames ?? []
                return {
                    ...(response || {}),
                    userType: {
                        isInvestor: response?.currentUserType === 'Investor',
                        isMixed: response?.currentUserType === 'Mixed',
                        isOriginator: response?.currentUserType === 'Originator',
                        isNone: response?.currentUserType === 'None',
                        isAdministrator:
                            roleNames?.filter(function (value) {
                                return value?.toLowerCase()?.indexOf('Administrator'.toLowerCase()) !== -1
                            }).length > 0 ?? false,
                        isBiMatching:
                            roleNames?.filter(function (value) {
                                return value?.toLowerCase()?.indexOf('BI Platform Matching'.toLowerCase()) !== -1
                            }).length > 0 ?? false,
                        isBiAdmin:
                            roleNames?.filter(function (value) {
                                return value?.toLowerCase()?.indexOf('BI Platform Admin'.toLowerCase()) !== -1
                            }).length > 0 ?? false,
                        isAdmin:
                            roleNames?.filter(function (value) {
                                return (
                                    value?.toLowerCase()?.indexOf('Administrator'.toLowerCase()) !== -1 ||
                                    value?.toLowerCase()?.indexOf('Sales'.toLowerCase()) !== -1 ||
                                    value?.toLowerCase()?.indexOf('Operations'.toLowerCase()) !== -1
                                )
                            }).length > 0 ?? false,
                        isWLP:
                            roleNames?.filter(function (value) {
                                return value?.toLowerCase()?.indexOf('WlpAdmin'.toLowerCase()) !== -1
                            }).length > 0 ?? false,
                        isSuperAdmin:
                            roleNames?.filter(function (value) {
                                return value?.toLowerCase()?.indexOf('Administrator'.toLowerCase()) !== -1
                            }).length > 0 ?? false,
                    },
                }
            },
            extraOptions: { maxRetries: 0 },
        }),
        saveUserProfile: builder.mutation<any, IReduxUser.ISaveUserProfileBody>({
            query: body => ({
                url: '/v1/myprofile',
                method: 'PUT',
                body,
            }),
        }),
        updatePasswordLink: builder.mutation<any, { email: string; newPassword: string; resetToken: string }>({
            query: body => ({
                url: '/v1/resetpasswordapi',
                method: 'POST',
                body,
            }),
        }),
        registerLite: builder.mutation<any, IReduxUser.IIRegister>({
            query: body => ({
                url: '/v2/registeruser',
                method: 'POST',
                body,
            }),
        }),
        register: builder.mutation<any, IReduxUser.IIRegister>({
            query: body => ({
                url: '/v1/registeruser',
                method: 'POST',
                body,
            }),
        }),
        forgotPasswordLink: builder.mutation<any, { Email: string; WhitelabelUrl: string }>({
            query: body => ({
                url: '/v1/recoverpassword',
                method: 'POST',
                body,
            }),
        }),
        resendEmailVerification: builder.mutation<any, { Token: string }>({
            query: body => ({
                url: '/v1/resend_confirm_email',
                method: 'POST',
                headers: {
                    Authorization: body.Token,
                },
            }),
        }),
        confirmEmail: builder.mutation<any, { userId: string | number; code: string; verificationReason: string | number }>({
            query: body => ({
                url: '/v1/confirm_email',
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const {
    useRegisterLiteMutation,
    useRegisterMutation,
    useGetUserProfileQuery,
    useSaveUserProfileMutation,
    useForgotPasswordLinkMutation,
    useResendEmailVerificationMutation,
    useUpdatePasswordLinkMutation,
    useConfirmEmailMutation,
} = userApi

export const userQueryReducer = { [reducerPath]: userApi.reducer }
export const userQuerySelector = state => state[reducerPath]

import GeneralDrawer from 'app/components/Layout/GeneralDrawer'
import React, { Context } from 'react'
import { CommentDrawer } from './components/CommentDrawer'
import { CompanyDrawer } from './components/CompanyDrawer'
import { GeneralContentDrawer } from './components/GeneralDrawer'
import { InvestorPreferenceDrawer } from './components/InvestorPreferencesDrawer'
import { TeamDrawer } from './components/TeamDrawer'
import { UserDrawer } from './components/UserDrawer'
import {
    CategoryDrawer,
    ICommentDrawerPropsData,
    ICompanyDrawerPropsData,
    IDrawerContext,
    IInvestorPreferenceDrawerPropsData,
    ITeamDrawerPropsData,
    IUserDrawerPropsData,
    Options,
    Props,
} from './definition'

let DrawerContext: Context<IDrawerContext>

const getDrawerContext = <TProps,>(): Context<IDrawerContext<TProps>> => {
    return DrawerContext as unknown as Context<IDrawerContext<TProps>>
}

const { Provider } = (DrawerContext = React.createContext<IDrawerContext>({
    isOpen: false,
    content: '',
    onClose: () => {},
    onHandle: () => () => {},
    category: 'general',
    props: {},
}))

const DrawerProvider = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [props, setProps] = React.useState<Props>({})
    const [category, setCategory] = React.useState<CategoryDrawer>('')
    const [content, setContent] = React.useState('')
    const [options, setOptions] = React.useState<Options>({})

    const open = (content: string, _props: any, _options?: Options): void => {
        setProps(_props)

        if (_options) {
            setOptions(_options)
        }

        if (content) {
            setContent(content)
        }
    }

    const onClose = () => {
        // Additional action
        options?.onClose?.()

        // Reset when modal closed
        setIsOpen(false)
        setOptions({})
        setContent('')
        setCategory('')
        setProps({})
    }

    const onHandle: any = (category: CategoryDrawer) => {
        setCategory(category)
        setIsOpen(true)
        if (category === 'investor-preferences') {
            return (_props: IInvestorPreferenceDrawerPropsData, _options?: Options) => open('', _props, _options)
        }

        if (category === 'company') {
            return (_props: ICompanyDrawerPropsData, _options?: Options) => open('', _props, _options)
        }

        if (category === 'user') {
            return (_props: IUserDrawerPropsData, _options?: Options) => open('', _props, _options)
        }

        if (category === 'team') {
            return (_props: ITeamDrawerPropsData, _options?: Options) => open('', _props, _options)
        }

        if (category === 'general') {
            return (content: string, _props: Props = {}, _options?: Options) => open(content, _props, _options)
        }

        if (category === 'comment') {
            return (_props: ICommentDrawerPropsData, _options?: Options) => open('', _props, _options)
        }
    }

    return (
        <Provider value={{ isOpen, onHandle, onClose, content, category, props }}>
            <GeneralDrawer title={props?.title ?? 'Drawer'} isOpen={isOpen} onChangeOpenDrawer={onClose}>
                {/* Register reusable drawer here */}
                <TeamDrawer />
                <UserDrawer />
                <CompanyDrawer />
                <InvestorPreferenceDrawer />
                <GeneralContentDrawer />
                <CommentDrawer />
            </GeneralDrawer>

            {children}
        </Provider>
    )
}

export { DrawerContext, DrawerProvider, getDrawerContext }

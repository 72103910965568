import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'

import config from 'config/index'
import { IReduxMarketplace } from './marketplace'

const reducerPath = 'marketplaceApi'

export const marketplaceApi = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.ACTIVITY_URL),
    endpoints: builder => ({
        getOpportunityPermission: builder.query<IReduxMarketplace.IOpportunityPermissionResponse, string>({
            query: opportunityId => ({
                url: `/marketplace/v1/opportunities/${opportunityId}/permissions/`,
                method: 'GET',
            }),
        }),
    }),
})

export const { useGetOpportunityPermissionQuery } = marketplaceApi

export const marketplaceQueryReducer = { [reducerPath]: marketplaceApi.reducer }
export const marketplaceQuerySelector = state => state[reducerPath]

import { Slide } from '@material-ui/core'
import React, { Context, useCallback, useEffect, useMemo } from 'react'

import { ICommentContext, ICommentData, IOnHandleParam } from '../../components/Comment/definition'
import { CommentContentType, CommentStatus } from 'store/modules/comment/enum'
import { useDispatch } from 'react-redux'

import { commentApi, commentSlice } from 'store/modules/comment'
import CommentRoomDialog from '../../components/Comment/CommentRoomDialog'

let CommentContext: Context<ICommentContext>

const getCommentContext = (): Context<ICommentContext> => {
    return CommentContext as unknown as Context<ICommentContext>
}

const { Provider } = (CommentContext = React.createContext<ICommentContext>({
    onHandle: () => {},
}))

const defaultState: ICommentData = {
    elementId: '',
    contentTypeId: '',
    contentType: CommentContentType['Opportunity'],
    permissionMeta: {
        id: '',
        type: 'opportunity',
    },
    disableAutoFixPosition: false,
}

const CommentProvider = ({ children }) => {
    const dispatch = useDispatch()

    const [commentRoomData, setCommentRoomData] = React.useState<ICommentData>(defaultState)

    const param = useMemo(
        () => ({
            id: commentRoomData.contentTypeId,
            contentType: commentRoomData.contentType,
        }),
        [commentRoomData],
    )

    const { data } = commentApi.endpoints.getComments.useQueryState(param, { skip: !commentRoomData.elementId })

    const onHandle = useCallback(
        (data: IOnHandleParam) => {
            dispatch(commentSlice.actions.setCurrentOpenedEelementId(data.elementId))
            setCommentRoomData(data)
        },
        [dispatch],
    )

    const onClose = useCallback(() => {
        dispatch(commentSlice.actions.setCurrentOpenedEelementId(''))

        const threadId = data?.elementIdToThreadId?.[commentRoomData.elementId]
        if (data && threadId) {
            const thread = data.thread[threadId]
            if (thread.status === CommentStatus['Resolve'] && thread.justResolved) {
                dispatch(
                    commentApi.util.updateQueryData('getComments', param, draft => {
                        Object.assign(draft, {
                            ...data,
                            thread: {
                                ...data.thread,
                                [threadId]: {
                                    ...thread,
                                    justResolved: false,
                                },
                            },
                            elementIdToThreadId: {
                                ...data.elementIdToThreadId,
                                [commentRoomData.elementId]: undefined,
                            },
                        })
                    }),
                )
            }
        }

        setCommentRoomData(defaultState)
    }, [commentRoomData.elementId, data, dispatch, param])

    const headerProps = useMemo(() => {
        return {
            onClose,
        }
    }, [onClose])

    useEffect(() => {
        return () => {
            dispatch(commentSlice.actions.setCurrentOpenedEelementId(''))
        }
    }, [dispatch])

    return (
        <Provider value={{ onHandle }}>
            <div style={{ zIndex: 101, position: 'relative' }}>
                <Slide direction="left" in={!!commentRoomData.elementId} mountOnEnter unmountOnExit>
                    <CommentRoomDialog
                        permissionMeta={commentRoomData.permissionMeta}
                        headerProps={headerProps}
                        threadMeta={commentRoomData}
                        showThreadCreationOnEmpty
                        disableAutoFixPosition={!!commentRoomData.disableAutoFixPosition}
                    />
                </Slide>
            </div>

            {children}
        </Provider>
    )
}

export { CommentContext, CommentProvider, getCommentContext }

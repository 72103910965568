import {
    AppBar,
    Avatar,
    Badge,
    Box,
    Button,
    IconButton,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tab,
    Tabs,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import _ from 'lodash'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CalendarToday from '@material-ui/icons/CalendarToday'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import MenuIcon from '@material-ui/icons/Menu'
import SettingsIcon from '@material-ui/icons/Settings'
import NotificationsIcon from '@material-ui/icons/NotificationsNone'
import config from 'config/index'
import React, { memo, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, Route, useHistory, useParams } from 'react-router-dom'
import { useGetInvestorStatusNDAQuery } from 'store/modules/investor/investor.query'
import { IReduxMaster } from 'store/modules/master/master'
import { useGetSchedulesQuery } from 'store/modules/schedule/schedule.query'
import { useGetUserProfileQuery } from 'store/modules/user'
import { colorPalette } from 'styles/mainTheme'
import { AuthUtil } from 'utils/auth'
import { formatSchedule } from 'utils/schedule/formatSchedule'
import { getToken } from 'utils/localStorage/token'
import logo from '../../../../assets/logo_finitive.png'
import NotificationsDrawer from '../NotificationsDrawer'
import CloseSharpIcon from '@material-ui/icons/CloseSharp'
import { useGetActivityQuery } from 'store/modules/activity/activity.query'
import AdminIcon from '../../../../assets/icons/admin.svg'
import { getModalContext } from 'app/context/modal/provider'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: 95,
        backgroundColor: 'white',
        justifyContent: 'center',
        boxShadow: 'none',
        paddingLeft: '0px',
        paddingRight: '52px',
        color: colorPalette.gray2Secondary,
        zIndex: theme.zIndex.drawer + 1,
        position: 'fixed',
        [theme.breakpoints.down('sm')]: {
            height: 67,
            paddingRight: theme.spacing(2),
        },
    },
    tabs: {
        backgroundColor: 'none',
        '& .Mui-selected': {
            backgroundColor: colorPalette.lightBluePrimary,
            borderRadius: '4px',
            height: '93px',
        },
        '& .MuiTab-textColorInherit': {
            opacity: 1,
            height: '93px',
        },
        '& .MuiTabs-indicator': {},
    },
    toolbar: {
        padding: '0px',
        justifyContent: 'flex-end',
        height: '100%',
    },
    logoSection: {
        display: 'flex',
        backgroundColor: 'none',
        width: 316,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
        position: 'absolute',
        left: 0,
        [theme.breakpoints.down('sm')]: {
            width: 258,
        },
    },
    profileSection: {
        display: 'flex',
        gap: theme.spacing(0),
        whiteSpace: 'pre',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    menuIcon: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    linkTextActive: {
        color: colorPalette.primary,
        fontSize: 16,
        '&.Mui-selected': {
            outline: 'none',
        },
        '&:active': {
            outline: 'none',
        },
    },

    boxName: {
        display: 'flex',
    },
    textName: {
        float: 'right',
        fontWeight: 700,
    },
    profileMenu: {
        top: theme.spacing(10) + 'px !important',
        [theme.breakpoints.down('sm')]: {
            top: theme.spacing(5) + 'px !important',
        },
    },
    badgeIcon: {
        zIndex: 1,
        fontSize: '20px',
    },
}))

function stringAvatar(fullname: string) {
    return {
        children: fullname
            .split(' ')
            .map(n => n[0])
            .join(''),
    }
}

type Props = {
    adminPage?: boolean
    isSignup?: boolean
    whiteLabelInfo?: IReduxMaster.IWhiteLabelInfoResult
    isWhitelabelLoading?: boolean
    isPreview?: boolean
    previewCloseTarget?: string
}

export default memo(function NavigationBar({
    adminPage,
    isSignup = true,
    whiteLabelInfo,
    isWhitelabelLoading = false,
    isPreview = false,
    previewCloseTarget,
}: Props) {
    const classes = useStyles()
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('xs'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery(theme.breakpoints.down('md'))
    const isCompactMenu = useMediaQuery(`(max-width: 1200px)`)
    const history = useHistory()
    // Progress section

    const modal = useContext(getModalContext())
    const dispatch = useDispatch()

    const access_token = getToken('access_token')

    const handleUniversalInvite = () => {
        modal.onHandle('universal-invite')({
            level2Status: '',
            opportunityId: '',
        })
    }

    // Initialize data
    const { data, isLoading } = useGetUserProfileQuery({}, { skip: !access_token })

    const { data: activities, isLoading: isLoadingActivity } = useGetActivityQuery({}, { skip: !access_token })

    const isHaveInvestorAccess = data?.userType.isAdmin || data?.userType.isInvestor || data?.userType.isMixed

    // const { data: investorStatusNDA } = useGetInvestorStatusNDAQuery(
    //     {},
    //     { skip: !access_token || !(data?.userType.isInvestor || data?.userType.isMixed) },
    // )

    const { data: schedules, isLoading: isScheduleLoading } = useGetSchedulesQuery(
        { schedule_endpoint: `/scheduling/schedule/` },
        { skip: !access_token },
    )

    let notifications
    if (!isScheduleLoading) {
        notifications = formatSchedule(schedules?.filter(item => item.meetingStatus === 'Pending' && !_.isEmpty(item.myRoles))).length
    }

    let numberOfUnread
    if (!isLoadingActivity) {
        numberOfUnread = activities?.results.filter((x, i) => {
            return x.unread
        }).length
    }

    const { data: investorStatusNDA } = useGetInvestorStatusNDAQuery({}, { skip: !access_token || !isHaveInvestorAccess })

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [openNotificationDrawer, setOpenNotificationDrawer] = React.useState<boolean>(false)
    const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null)
    // const [setAnchorCMSMenu] = React.useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    const navigationMenuOpen = Boolean(anchorElMenu)
    // const cmsMenuOpen = Boolean(anchorCMSMenu)
    const investorNDASigned = investorStatusNDA?.ndaId !== 'NoNda'

    const handleUserProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleNavigationMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElMenu(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleCloseNavigationMenu = () => {
        setAnchorElMenu(null)
    }

    // const handleCMSMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorCMSMenu(event.currentTarget)
    // }

    // const handleCloseNavigationCMSMenu = () => {
    //     setAnchorCMSMenu(null)
    // }

    const handleLogout = () => {
        // TODO: add API call for logout
        AuthUtil.logout(dispatch)
        history.push('/')
    }

    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault()

    let profileSection
    const navButton = false

    const { contentItemId } = useParams<{ contentItemId: string }>()

    if (isPreview) {
        return (
            <AppBar
                className={classes.root}
                style={{
                    borderBottom: adminPage ? '1px solid' + colorPalette.primary : '1px solid' + colorPalette.grayBlueSecondary,
                    boxShadow: '0px 2px 14px 1px rgb(0 0 0 / 19%)',
                }}
                position="absolute"
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        margin: '0 auto',
                        paddingLeft: '40px',
                    }}
                >
                    <h3>Preview</h3>
                    <IconButton
                        id={contentItemId}
                        onClick={() => (previewCloseTarget ? history.push(previewCloseTarget) : history.goBack())}
                    >
                        <CloseSharpIcon />
                    </IconButton>
                </div>
            </AppBar>
        )
    }

    const displayNavigationAsTabs = () => {
        /**
         * There's 3 icon in second tabs: calendar, notification, and admin.
         * Why the valid value is only "calendar" because the notification and admin use "onClick"
         */
        const isValidSecondTabValue = history.location.pathname === '/calendar'
        return (
            <Route
                path="/"
                render={history => (
                    <>
                        <Tabs
                            className={classes.tabs}
                            value={
                                history.location.pathname !== '/'
                                    ? history.location.pathname.split('/')[1] === 'transaction' &&
                                      history.location.pathname.split('/')[2] !== 'originator'
                                        ? '/marketplace'
                                        : history.location.pathname
                                    : false
                            }
                        >
                            {true ? (
                                <Tab
                                    className={classes.linkTextActive}
                                    value="/dashboard"
                                    label="Dashboard"
                                    component={RouterLink}
                                    to="/dashboard"
                                    style={{ textDecoration: 'none' }}
                                />
                            ) : null}
                            {(data?.userType.isAdmin || data?.userType.isInvestor || data?.userType.isMixed) &&
                            investorNDASigned &&
                            (whiteLabelInfo?.webConfiguration?.useSSO || whiteLabelInfo?.webConfiguration.appURL === '') ? (
                                <Tab
                                    className={classes.linkTextActive}
                                    value="/marketplace"
                                    label="Marketplace"
                                    component={RouterLink}
                                    to="/marketplace"
                                    style={{ textDecoration: 'none' }}
                                />
                            ) : null}
                        </Tabs>

                        <Tabs value={isValidSecondTabValue ? history.location.pathname : false} className={classes.tabs}>
                            {data?.userType.isAdmin && (
                                <Tab
                                    icon={
                                        <Button
                                            // @ts-ignore:next-line
                                            onClick={handleUniversalInvite}
                                            style={{ backgroundColor: '#5FB164' }}
                                            variant="contained"
                                            size="large"
                                            startIcon={<AddCircleOutlineIcon />}
                                        >
                                            Invite
                                        </Button>
                                    }
                                    label=""
                                    component={RouterLink}
                                    to="#"
                                />
                            )}
                            <Tab
                                icon={
                                    <Badge
                                        badgeContent={notifications}
                                        color="error"
                                        style={{ transform: 'translate(0px, 13px)', right: '15px' }}
                                    >
                                        <IconButton
                                            data-testid="navigation-menu-admin"
                                            style={{ position: 'relative', top: '-15px', left: '12px' }}
                                        >
                                            <CalendarToday
                                                className={classes.badgeIcon}
                                                style={{
                                                    color: colorPalette.primary,
                                                    fontSize: '22px',
                                                    position: 'relative',
                                                }}
                                            />
                                        </IconButton>
                                    </Badge>
                                }
                                className={classes.linkTextActive}
                                value="/calendar"
                                label=""
                                component={RouterLink}
                                to="/calendar"
                                style={{ textDecoration: 'none', width: '80px' }}
                            />
                            {(data?.userType.isAdmin ||
                                window.location.hostname.includes('dev') ||
                                window.location.hostname.includes('localhost')) && (
                                <Tab
                                    icon={
                                        <Badge
                                            badgeContent={numberOfUnread}
                                            color="error"
                                            style={{ transform: 'translate(0px, 15px)', right: '20px' }}
                                        >
                                            <IconButton
                                                data-testid="navigation-menu-admin"
                                                style={{ position: 'relative', top: '-15px', left: '15px' }}
                                            >
                                                <NotificationsIcon
                                                    className={classes.badgeIcon}
                                                    style={{
                                                        color: colorPalette.primary,
                                                        fontSize: '27px',
                                                        position: 'relative',
                                                    }}
                                                />
                                            </IconButton>
                                        </Badge>
                                    }
                                    className={classes.linkTextActive}
                                    label=""
                                    style={{ textDecoration: 'none', width: '60px' }}
                                    onClick={() => setOpenNotificationDrawer(!openNotificationDrawer)}
                                />
                            )}

                            {data?.userType.isAdmin && (
                                <Tab
                                    className={classes.linkTextActive}
                                    style={{ padding: 0 }}
                                    onClick={() => window.open(`${config.WEB_URL}/admin`, '_blank')}
                                    icon={
                                        <>
                                            <IconButton data-testid="navigation-menu-admin" style={{ backgroundColor: 'none' }}>
                                                <img
                                                    src={AdminIcon}
                                                    style={{ color: colorPalette.primary, top: '-2px', position: 'relative' }}
                                                    height={25}
                                                    width={25}
                                                    alt="admin icon"
                                                />
                                            </IconButton>
                                            {/* <Menu
                                            id="profile-menu"
                                            anchorEl={anchorCMSMenu}
                                            getContentAnchorEl={null}
                                            open={cmsMenuOpen}
                                            onClose={handleCloseNavigationCMSMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                            PaperProps={{ className: classes.profileMenu }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuItem>
                                                <a href={`${config.WEB_URL}/admin`}>
                                                    <ListItemText disableTypography style={{ color: '#013C4D' }}>
                                                        CMS
                                                    </ListItemText>
                                                </a>
                                            </MenuItem>
                                        </Menu> */}
                                        </>
                                    }
                                />
                            )}
                        </Tabs>
                    </>
                )}
            />
        )
    }

    const displayNavigationAsMenu = () => {
        return (
            <Route
                path="/"
                render={history => (
                    <>
                        <IconButton data-testid="navigation-menu-button" onClick={handleNavigationMenuClick}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="navigation-menu"
                            anchorEl={anchorElMenu}
                            getContentAnchorEl={null}
                            open={navigationMenuOpen}
                            onClose={handleCloseNavigationMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            PaperProps={{ className: classes.profileMenu }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            {true ? (
                                <div>
                                    <MenuItem className={classes.linkTextActive} component={RouterLink} to="/dashboard">
                                        Dashboard
                                    </MenuItem>
                                </div>
                            ) : null}

                            {(data?.userType.isAdmin || data?.userType.isInvestor || data?.userType.isMixed) && investorNDASigned ? (
                                <MenuItem className={classes.linkTextActive} component={RouterLink} to="/marketplace">
                                    Marketplace
                                </MenuItem>
                            ) : null}
                            <MenuItem className={classes.linkTextActive} component={RouterLink} to="/calendar">
                                Calendar
                            </MenuItem>

                            {(data?.userType.isAdmin ||
                                window.location.hostname.includes('dev') ||
                                window.location.hostname.includes('localhost')) && (
                                <MenuItem
                                    className={classes.linkTextActive}
                                    onClick={() => setOpenNotificationDrawer(!openNotificationDrawer)}
                                >
                                    Notifications
                                </MenuItem>
                            )}

                            {data?.userType.isAdmin ? (
                                <MenuItem
                                    className={classes.linkTextActive}
                                    component="a" // Necessary in order to open in a new tab (https://github.com/mui-org/material-ui/issues/204)
                                    href={`${config.WEB_URL}/admin`}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    CMS
                                </MenuItem>
                            ) : null}
                            {isSmall ? (
                                <>
                                    <MenuItem component={RouterLink} to="/profile">
                                        <ListItemText disableTypography style={{ color: '#013C4D' }}>
                                            Settings
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout}>
                                        <ListItemText disableTypography style={{ color: '#013C4D' }}>
                                            Log out
                                        </ListItemText>
                                    </MenuItem>
                                </>
                            ) : null}
                        </Menu>
                    </>
                )}
            />
        )
    }

    // If user logged in

    if (access_token && data) {
        if (data.user.approvalStatus === 'Pending' || data.user.approvalStatus === 'Denied') {
            profileSection = (
                <>
                    <Box className={classes.profileSection}>
                        <Button data-testid="profile-menu-button" variant="text" disableRipple onClick={handleUserProfileClick}>
                            <Avatar
                                style={{
                                    backgroundColor: colorPalette.primary,
                                    fontSize: 18,
                                    height: '45px',
                                    width: '45px',
                                }}
                                {...stringAvatar(data?.profilePart?.fullName ?? '')}
                            />
                        </Button>

                        <Menu
                            id="profile-menu"
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            PaperProps={{ className: classes.profileMenu }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon style={{ color: '#013C4D', minWidth: '36px' }}>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography style={{ color: '#013C4D' }}>
                                    Log out
                                </ListItemText>
                            </MenuItem>
                        </Menu>
                    </Box>
                </>
            )
        } else {
            profileSection = (
                <>
                    {isTablet || isCompactMenu ? displayNavigationAsMenu() : displayNavigationAsTabs()}
                    <Box className={classes.profileSection}>
                        <Button data-testid="profile-menu-button" variant="text" disableRipple onClick={handleUserProfileClick}>
                            <Tooltip title={data?.profilePart?.fullName ?? 'My Account Information and Settings'}>
                                <Avatar
                                    style={{
                                        backgroundColor: colorPalette.primary,
                                        fontSize: 18,
                                        height: '45px',
                                        width: '45px',
                                    }}
                                    {...stringAvatar(data?.profilePart?.fullName ?? '')}
                                />
                            </Tooltip>
                        </Button>

                        <Menu
                            id="profile-menu"
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            PaperProps={{ className: classes.profileMenu }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem component={RouterLink} to="/profile">
                                <ListItemIcon style={{ color: '#013C4D', minWidth: '36px' }}>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography style={{ color: '#013C4D' }}>
                                    Settings
                                </ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon style={{ color: '#013C4D', minWidth: '36px' }}>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText disableTypography style={{ color: '#013C4D' }}>
                                    Log out
                                </ListItemText>
                            </MenuItem>
                        </Menu>
                    </Box>
                </>
            )
        }
    } else if (navButton) {
        profileSection = (
            <Box className={classes.profileSection}>
                <Typography color="primary">
                    <Link href="/marketplace" className={classes.linkTextActive} onClick={preventDefault} underline="none">
                        Marketplace
                    </Link>
                </Typography>
                <Box className={classes.boxName}>
                    {data && (
                        <Typography variant="body1" color="primary" className={classes.textName}>
                            {data?.profilePart?.fullName}
                        </Typography>
                    )}
                </Box>
            </Box>
        )
    } else if (!isLoading) {
        profileSection = (
            <Box display="flex" alignItems="center">
                <Typography style={{ textDecoration: 'none' }} component={RouterLink} to={isSignup ? '/signup' : '/login'}>
                    <Button variant="outlined"> {isSignup ? 'Sign Up' : 'Login'} </Button>
                </Typography>
            </Box>
        )
    }

    // console.log(<NotificationsDrawer isOpen={openNotificationDrawer} onChangeOpenDrawer={open => setOpenNotificationDrawer(open)} />)

    return (
        <Box display="flex">
            <NotificationsDrawer isOpen={openNotificationDrawer} onChangeOpenDrawer={open => setOpenNotificationDrawer(open)} />
            <AppBar
                className={classes.root}
                style={{
                    borderBottom: adminPage ? '1px solid' + colorPalette.primary : '1px solid' + colorPalette.grayBlueSecondary,
                    boxShadow: '0px 2px 14px 1px rgb(0 0 0 / 19%)',
                }}
                position="absolute"
            >
                <Toolbar className={classes.toolbar}>
                    <Link component={RouterLink} to="/" className={classes.logoSection}>
                        {!isWhitelabelLoading && (
                            <img
                                src={
                                    whiteLabelInfo?.webConfiguration?.logoHeaderPath
                                        ? `${config.WEB_URL}media/${whiteLabelInfo?.webConfiguration?.logoHeaderPath}`
                                        : logo
                                }
                                alt="Logo"
                                style={{ height: '43px' }}
                            />
                        )}

                        {!isMobile ? (
                            <>
                                {/* <Typography> | </Typography> */}
                                {/* <Typography variant="body2">Intelligent Credit Marketplace</Typography> */}
                            </>
                        ) : null}
                    </Link>
                    {profileSection}
                </Toolbar>
            </AppBar>
        </Box>
    )
})

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FullStoryAPI } from 'react-fullstory'

import { useHistory, useLocation, Route, RouteProps } from 'react-router-dom'

import { userApi } from 'store/modules/user'
import { generalSlice } from 'store/modules/general'

import { getToken } from 'utils/localStorage/token'

interface IProps extends RouteProps {
    allowUnapproved?: boolean
}

/**
 * A route that require an auth
 */
export const AuthRoute = ({ allowUnapproved = false, ...props }: IProps) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()
    const access_token = getToken('access_token')

    const { data, isLoading: isGetUserProfileLoading } = userApi.endpoints.getUserProfile.useQueryState({})

    const { email = '', emailConfirmed = false, approvalStatus } = data?.user || {}

    /**
     * Save previous path and redirect to login page.
     * setRedirectToPath used in login page to determine the next page
     */
    useEffect(() => {
        const pathname = location.pathname
        const queryParamString = location?.search ?? ''

        if (!access_token && pathname !== '/') {
            dispatch(generalSlice.actions.setRedirectToPath(`${pathname}${queryParamString}`))
            history.push('/')
        } else {
            dispatch(generalSlice.actions.setRedirectToPath(''))
        }
    }, [dispatch, location, history, access_token])

    /**
     * Redirect logged in user to /email-confirmation page when email is not confirmed.
     */
    useEffect(() => {
        if (access_token && email && !isGetUserProfileLoading && !emailConfirmed) {
            history.push(`/email-confirmation?email=${encodeURIComponent(email)}`)
        }
    }, [history, access_token, isGetUserProfileLoading, email, emailConfirmed])

    useEffect(() => {
        if (data && !isGetUserProfileLoading) {
            FullStoryAPI('setUserVars', {
                displayName: data.profilePart.fullName,
                type: data.currentUserType,
                uid: data.user.contentItemId,
                companyid: data?.managingCompanyIds?.[0] ?? '',
            })
        }
    }, [data, isGetUserProfileLoading])

    /**
     * Redirect user that not approved to the dashboard page when access this page
     */
    useEffect(() => {
        if (access_token && !allowUnapproved && approvalStatus === 'Pending') {
            history.push('/')
        }
    }, [access_token, allowUnapproved, approvalStatus, history])

    return <Route {...props} />
}
